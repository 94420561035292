<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>
    <b-alert 
      v-model="flagAlertProfile" 
      variant="warning" 
      dismissible>
        ¡Primero debes completar tu perfil! Ir a
        <b-link @click="goToProfile()">Crear perfil</b-link>
    </b-alert>
    <div 
      class="m-4"
      v-if="!flagAlertProfile">
      <CToaster :autohide="3000">
        <template v-for="toast in fixedToasts">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Envio exitoso">
            Tu solicitud ha sido enviada exitosamente.
          </CToast>
        </template>
      </CToaster>
    </div>
    <b-row>
      <b-col md="8" offset-lg="2">
        <h3>Peticiones, quejas y reclamos</h3>   
        <CCard>                      
          <CCardBody>
            Algo no está bien con el servicio. Déjanos tus comentarios.
            <b-row>
              <p></p>
            </b-row>
            <b-row class="my-1">              
              <b-col 
                lg="4" sm="3" 
                style="text-align:left"
                >
                <label for="account_type"><strong> Tipo de solicitud <small class="alert-text">*</small></strong></label>
              </b-col>
              <b-col lg="8" sm="9">                    
                <b-form-group>
                  <v-select 
                    v-model="complain.type"
                    :options="complains" 
                    placeholder="Selecciona una categoría"
                    :class="{'form-control is-invalid': $v.complain.type.$error,
                            'form-control is-valid': !$v.complain.type.$invalid}">
                  </v-select>                                     
                </b-form-group>                  
                <b-form-invalid-feedback v-if="!$v.complain.type.required">
                    El tipo de solicitud es obligatoria.
                </b-form-invalid-feedback>    
              </b-col>
            </b-row>

            <b-row class="my-1">
              <br>
              <b-col 
                lg="4" sm="3" 
                style="text-align:left">
                <label for="account_type"><strong> Descripción <small class="alert-text">*</small></strong></label>
              </b-col>
              <b-col lg="8" sm="9">                    
                <b-form-group>
                  <b-form-group>                                                           
                    <b-form-textarea                            
                      v-model.trim="$v.complain.description.$model"
                      rows="4"
                      max-rows="6"
                      :class="{'form-control is-invalid': $v.complain.description.$error,
                                'form-control is-valid': !$v.complain.description.$invalid}"
                      >
                    </b-form-textarea>                                                                   
                  </b-form-group>       
                  <b-form-invalid-feedback v-if="!$v.complain.description.required">
                      La descripción es obligatoria
                    </b-form-invalid-feedback>              
                </b-form-group>                    
              </b-col>
            </b-row>

            <b-row>                
              <b-col lg="3">
                <CButton
                  class="s-bg s-bg-text mr-1"
                  style="width:100%;"
                  @click="submit">
                  Enviar
                </CButton>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </b-col>

      <b-col md="8" offset-lg="2">
        <h3>¿Tienes alguna duda?</h3>   
        <CCard>                      
          <CCardBody>
            Contacta nuestro a nuestros asesores.
            <b-row>
              <b-col lg="6">
                <CButton
                    class
                    name="Whatsapp"
                    size="sm"
                    color="xing"
                    target="_blank"
                    @click.prevent="whatsapp('help')"
                >
                    <CIcon size="lg" :name="'cib-Whatsapp'" />Whatsapp
                </CButton>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </b-col>

     
    </b-row>

  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { required  } from 'vuelidate/lib/validators';
export default {
  name: "support",
  components: {Loading},
  data() {
    return {
      loading: false,
      flagAlertProfile: false,
      complains: ['Petición', 'Queja', 'Reclamo'],
      complain: {
        type: null,
        description: null,
        city: '',
      },
      wp: "https://api.whatsapp.com/send?phone=",
      fixedToasts: 0,
      userProfile: null,
      cities: null,
      cityMatched: null,
    }
  },
  validations: {
    complain: {
      type: {
        required,
      },
      description: {
        required,
      },   
      city: {}   
    }
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    if(this.userProfile == null) {      
      this.flagAlertProfile = true
    } else {
      console.log(this.userProfile)
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    submit () {      
      this.$v.$touch()
      if (this.$v.$invalid) {                    
        this.error = true
        this.errors = ['No has completado correctamente tus datos']
        this.scrollToTop()                 
      } else {                
        this.loading = true
        this.postCreateComplain()
      }
    },
    postCreateComplain () {
      
      this.complain['userId'] = this.userProfile.id;      
      this.complain['city'] = {
        value: this.userProfile.ciudades.id,
        label: this.userProfile.ciudades.nombre
      };   
      console.log(this.complain)             
      let createComplain = this.$firebase.functions().httpsCallable('createComplain');
      createComplain(this.complain).then((response) => {
        // console.log(response.data.data)
        this.fixedToasts++
        this.complain.type = null
        this.complain.description = null
      }).catch((error) => {
        console.log(error)
      }).finally(_=>this.loading=false)
    },
    whatsapp(subject) {
      let message = "";      
      if (subject == "help") {
        this.wp = this.wp + "573194115792";
        message =
          this.wp +
          "&text=Hola Go Guau, los contacto para solicitar su ayuda.";
      }
      window.open(message, "_blank");
    },
    goToProfile() {
      this.$router.push({name: 'users-profile'});
    },
  }

}
</script>

<style>

</style>